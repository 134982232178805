<template>
  <layout-full-width :title="$t('projectNumber') + ' #' + $route.params.projectNumber"
                     :badge="this.status">
    <component-project @updateStatus="updateStatus"
                       :project-number="projectNumber"
                       :project-pin="projectPin"/>
  </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentProject from "@/components/ComponentProject";

export default {
  name: "PageEditProject",
  components: { LayoutFullWidth, ComponentProject},
  computed: {
    projectNumber() {
      return this.$route.params.projectNumber;
    },
    projectPin() {
      return this.$route.params.projectPin;
    }
  },
  data() {
    return {
      status: null
    }
  },
  methods: {
    updateStatus(status) {
      this.status = status;
    }
  }
}
</script>